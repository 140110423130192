import request from "@/utils/request";

// 获取课程列表
export async function getAllClasses() {
    try {
        return await request({
            url: `/class/all-course`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}

/**
 * 获取课程试听内容
 * */
export async function getClassListion(courseId) {
    try {
        return await request({
            url: `/class/${courseId}/trial`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}

// 获取课程价格详情

export async function getCourseDetail(courseId) {
    try {
        return await request({
            url: `/class/${courseId}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}

//leetcode 订阅
export async function getMinimumPrice() {
    try {
        return await request({
            url: `/subscriptions/getMinimumPrice`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}