<template>
  <div class="poly-video">
    <div id="polyvideo"></div>
  </div>
</template>
<script>
import { getClassListion } from "@/service/course";
export default {
  name: "PolyVideo",
  props: {
    classId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      player: null,
      vId: "",
    };
  },
  mounted() {
    this.initVideo();
  },
  methods: {
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.getInfos);
      });
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      if (this.player) {
        this.player.destroy();
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#polyvideo",
        width: "100%",
        height: 210,
        vid: this.vId,
      });
      // this.player.play();
      console.log(this.player);
    },
    getInfos() {
      getClassListion(this.classId)
        .then((res) => {
          if (res.result && res.result.length > 0) {
            this.vId = res.result[0].url;
            setTimeout(() => {
              this.loadPlayer();
            }, 300);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.poly-video {
  width: 100%;
  margin-top: -60px;
  img {
    width: 100%;
  }
}
#polyvideo {
  width: 90%;
  margin: 0 5%;
  & div {
    width: 100%;
    display: none !important;
  }
  // display: none;
}
</style>